<template>
  <div>
    <Heard />

    <div class="container-fluid login-box">
      <div class="container write-box">
        <el-card>
          <div class="chooseitem">
            <router-link to="/login" class="btn-title">密码登录</router-link>
            <router-link to="/login/login_phone" class="linebottom btn-title"
              >短信登录</router-link
            >
          </div>

          <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <el-form-item prop="mobile">
              <el-input
                v-model="ruleForm.mobile"
                placeholder="请输入手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-input
                type="password"
                v-model="ruleForm.code"
                placeholder="请输入验证码"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <!-- <p class="getcode" :disabled="disabled" @click="sendcode">
              {{ btntxt }}
            </p> -->

            <span class="getcode" v-show="show" @click="getCode"
              >获取验证码</span
            >
            <span v-show="!show" class="count getcode">{{ count }} 秒</span>
            <!-- <p class="getcode">获取中</p> -->
            <el-form-item>
              <div class="loginbtn" @click="submitForm('ruleForm')">登录</div>
            </el-form-item>
          </el-form>
          <!-- <div class="samtag">
            <router-link to="/register" class="nuxtlink">免费注册</router-link>
          </div> -->
          <!-- <router-link to="/login/login_wx" class="jumpwxlogin">
            <img src="@/assets/image/wxlogo.png" alt="" />
            微信登录
          </router-link> -->
        </el-card>
      </div>
    </div>
    <Otherfooter />
  </div>
</template>

<script>
import { api_sendMsg, api_authLogin } from "@/apis/apis";
import { setUser, setfile } from "@/plugins/auth.js";

import Heard from "@/components/Heard.vue";
import Otherfooter from "@/components/Otherfooter.vue";
export default {
  components: {
    Heard,
    Otherfooter,
  },
  data() {
    //手机号
    var checkmobile = (rule, value, callback) => {
      var re = /^1\d{10}$/;
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else {
        if (!re.test(value)) {
          callback(new Error("请输入正确的手机号"));
        }
        callback();
      }
    };
    //验证码
    var validatecode = (rule, value, callback) => {
      console.log(this.ruleForm.mobile);
      if (!value) {
        return callback(new Error("验证码不能为空"));
      } else {
        if (this.ruleForm.code != this.msgcode) {
          callback(new Error("验证码错误"));
        }
        callback();
      }
    };

    return {
      ruleForm: {
        code: "",
        mobile: "",
      },
      rules: {
        code: [{ validator: validatecode, trigger: "blur" }],
        mobile: [{ validator: checkmobile, trigger: "blur" }],
      },
      show: true,
      count: "",
      timer: null,
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api_authLogin({
            mobile: this.ruleForm.mobile,
            msgCode: this.ruleForm.code,
            loginType: 1, // 0: 账号密码登  ，1：短信验证码登录 2微信登录
          }).then((res) => {
            console.log(res);
            if (res.data.status === true) {
              // this.$store.commit("userstore/setUserInfo", res.data.data);
              setUser(
                res.data.data.token,
                res.data.data.user.id,
                res.data.data.user.avatar,
                res.data.data.user.mobile,
                res.data.data.user.nickName
              );
              setfile(res.data.data.user.filePathPc);
              this.$router.push("/");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    getCode() {
      if (this.ruleForm.mobile != "") {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
        api_sendMsg({
          mobile: this.ruleForm.mobile,
          msgType: 2,
        }).then((res) => {
          console.log(res);
          this.msgcode = res.data.data.code;
        });
      } else {
        this.$message({
          showClose: true,
          message: "请输入手机号",
          type: "error",
          offset: 60,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container-fluid {
  min-width: 1200px;
}
/* 版心 */
.container {
  width: 1200px;
  margin: 0 auto;
}
.login-box {
  height: 600px;
  background: url(~@/assets/image/loginbanner.png) no-repeat center;
  .write-box {
    position: relative;
    /deep/ .el-card__body {
      padding: 0;
    }
    .el-card {
      width: 360px;
      height: 400px;
      position: absolute;
      top: 100px;
      right: 100px;
      padding: 30px 40px 34px;

      .getcode {
        position: absolute;
        top: 170px;
        right: 70px;
        font-size: 14px;
        color: #2d69f9;
      }
      .chooseitem {
        display: flex;
        justify-content: space-between;
        margin-bottom: 34px;
        .btn-title {
          line-height: 30px;
          color: #7b7b7b;
          font-size: 20px;
          cursor: pointer;
        }
        .linebottom {
          border-bottom: 2px solid #2d69f9;
          color: #000;
          font-weight: bold;
        }
      }
      /deep/ .el-form-item {
        margin-bottom: 0;
      }
      .el-input {
        margin-bottom: 20px;
      }
      /deep/ .el-form-item__error {
        top: 63%;
      }
      // .fatipt {
      //   position: relative;
      // }
      .child {
        position: absolute;
        top: 168px;
        font-size: 14px;
        right: 56px;
        color: #2d69f9;
      }
      .loginbtn {
        height: 44px;
        background: #2d69f9;
        opacity: 1;
        color: #fff;
        text-align: center;
        line-height: 44px;
        letter-spacing: 4px;
        font-size: 16px;
      }
      .samtag {
        margin-top: 16px;
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        .nuxtlink {
          color: #225592;
        }
      }
      .jumpwxlogin {
        width: 160px;
        height: 40px;
        display: block;
        background: #f8f8fa;
        border-radius: 100px;
        margin: 0 auto;
        text-align: center;
        line-height: 40px;
        font-size: 12px;
        color: #7b7b7b;
      }
    }
  }
}
</style>